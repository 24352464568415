'use client';

import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useReCaptcha } from 'next-recaptcha-v3';

import { BeehiivPublicationIds } from '@blockworks/platform/api/beehiiv';
import { useIdentify, useTrack } from '@blockworks/platform/services/analytics';
import { Spinner } from '@blockworks/ui/spinner';
import { cn } from '@blockworks/ui/utils';

import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

type NewsletterSubscribeInputProps = {
    className?: string;
    inputPlaceholder: string;
    buttonText: string;
    source?: string;
    onButtonClick: typeof subscribeToNewsletter;
    defaultSubscription?: BeehiivPublicationIds[];
    size?: 'md' | 'lg';
};

const BOT_WAIT_TIME = 2000;

enum ErrorType {
    BotProtectionError = 'botProtectionError',
    GenericError = 'genericError',
    CaptchaError = 'captchaError',
}

interface ErrorState {
    type: ErrorType | null;
    message: string | null;
}

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export type EmailType = `${string}@${string}`;
const validateEmail = (email: EmailType) => {
    return EMAIL_REGEX.test(email);
};

const EmptyErrorState = { type: null, message: null };

const NewsletterSubscribeInput = (props: NewsletterSubscribeInputProps) => {
    const track = useTrack();
    const identify = useIdentify();
    const [isLoading, setIsLoading] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const { inputPlaceholder, buttonText, onButtonClick, className, source } = props;
    const [errorState, setErrorState] = useState<ErrorState>(EmptyErrorState);
    const [inputText, setInputText] = useState<string>('');
    const [verification, setVerification] = useState<string>('');
    const [placeholderText, setPlaceholderText] = useState<string>(inputPlaceholder);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { executeRecaptcha } = useReCaptcha(process.env.RECAPTCHA_SITEKEY);

    // time tracking
    const [startTime, setStartTime] = useState<number>(0);

    useEffect(() => {
        if (errorState.type === 'botProtectionError' && errorState.message) {
            timeoutRef.current = setTimeout(() => {
                setErrorState(EmptyErrorState);
            }, BOT_WAIT_TIME);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [errorState]);

    const handleSubmit = useCallback(
        async (e: { preventDefault: () => void }) => {
            e.preventDefault();

            const subscribeTo = props.defaultSubscription ?? [
                BeehiivPublicationIds.Daily,
                BeehiivPublicationIds.Weekly,
                BeehiivPublicationIds.Events,
                BeehiivPublicationIds.Webinars,
            ];

            const email = inputText.trim() as EmailType;

            // Bot protection:
            // Calculate time difference & only allow form submission if time diff is greater than 2 seconds
            if (startTime > 0 && Date.now() - startTime < BOT_WAIT_TIME) {
                setErrorState({
                    type: ErrorType.BotProtectionError,
                    message: 'Bot protection: Please wait a few seconds before submitting again.',
                });

                return;
            }

            // Honeypot - if the hidden field is filled, it's a bot
            if (verification !== '') {
                setErrorState({
                    type: ErrorType.GenericError,
                    message: 'Error.',
                });
                return;
            }

            if (!validateEmail(email)) {
                setErrorState({
                    type: ErrorType.GenericError,
                    message: 'Please enter a valid email address.',
                });
                return;
            }

            try {
                // Generate ReCaptcha token
                const captchaToken = await executeRecaptcha('signup');

                setIsLoading(true);
                onButtonClick({
                    email,
                    subscribeTo,
                    source: source ?? '',
                    sourcePath: window?.location?.pathname ?? '',
                    captchaToken,
                })
                    .then(result => {
                        // Identify only if user actually subscribed and checks have passed
                        identify(email, {
                            subscribedToNewsletter: true,
                        });

                        if (result && result!.success) {
                            setErrorState(EmptyErrorState);
                            setInputText('');
                            setPlaceholderText('Subscribed - Thank you!');
                            setSubscribed(true);
                            track('Newsletter.Subscribe', {
                                email,
                                subscribed: true,
                                source,
                                subscribeTo,
                                validatedCaptcha: result.validatedCaptcha ?? {},
                            });
                        } else {
                            setErrorState({
                                type: ErrorType.GenericError,
                                message: 'There was an error subscribing to our newsletter. Please try again.',
                            });
                            track('Newsletter.Subscribe.Error', {
                                email,
                                subscribed: false,
                                source,
                                subscribeTo,
                                result,
                            });
                        }
                        setIsLoading(false);
                    })
                    .catch(error => {
                        setErrorState({
                            type: ErrorType.GenericError,
                            message: `${error}`,
                        });
                        setIsLoading(false);
                    });
            } catch (error) {
                console.error(error);
                setErrorState({
                    type: ErrorType.CaptchaError,
                    message: `${error}`,
                });
                setIsLoading(false);
            }
        },
        [
            executeRecaptcha,
            inputText,
            onButtonClick,
            props.defaultSubscription,
            source,
            startTime,
            verification,
            identify,
            track,
        ],
    );

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    return (
        <div className="flex flex-col w-full">
            <div className={cn('flex justify-start items-center self-stretch', className)}>
                <div className="self-stretch flex-grow bg-white">
                    <input
                        type="hidden"
                        name="verification"
                        value={verification}
                        onChange={ev => setVerification(ev.target.value)}
                    />
                    <input
                        className={cn(
                            'flex justify-start items-start w-full text-light-gray focus:text-black border focus:outline-none border-r-0 outline-0',
                            !subscribed
                                ? 'border-gray-200 focus:border-brand'
                                : 'cursor-default border-green-400 focus:border-green-400',
                            props.size === 'lg' ? 'p-4 text-md' : 'p-2 text-xs',
                            errorState.message && 'border-red-400 focus:border-red-200',
                        )}
                        onFocus={() => setStartTime(Date.now())}
                        onInput={handleInputChange}
                        value={inputText}
                        placeholder={placeholderText}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                handleSubmit(e);
                            }
                        }}
                    />
                </div>
                <button
                    className={cn(
                        'flex justify-start items-center gap-1 border cursor-pointer border-l-0',
                        !subscribed
                            ? 'bg-brand border-brand hover:border-gray-800 hover:bg-gray-700'
                            : 'bg-green-400 border-green-400 cursor-default',
                        props.size === 'lg' ? 'px-8 py-4 text-md' : 'px-4 py-2 text-xs',
                        errorState.message && 'bg-red-400 border-red-400',
                    )}
                    disabled={subscribed}
                    onClick={handleSubmit}
                >
                    <span className="text-center uppercase text-white">
                        {!isLoading ? (
                            <>
                                {subscribed && (
                                    <CheckCircleIcon className={props.size === 'lg' ? 'w-6 h-6' : 'w-4 h-4'} />
                                )}
                                {!subscribed && <>{buttonText}</>}
                            </>
                        ) : (
                            <Spinner size={props.size === 'lg' ? 6 : 4} color="white" />
                        )}
                    </span>
                </button>
            </div>
            {errorState.message && (
                <div className="p-1.5 bg-red-50 border border-t-0 border-red-200 flex flex-auto text-xs text-red-500">
                    <p className="">{errorState.message}</p>
                    <p className="flex-1 text-right">
                        <span role="button" className="cursor-pointer" onClick={() => setErrorState(EmptyErrorState)}>
                            X
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};

export default NewsletterSubscribeInput;
